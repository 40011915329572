import { isMobile } from 'react-device-detect'
import { FaCheck } from 'react-icons/fa'
import Switch from 'react-switch'
import { segment } from 'src/lib/Segments'

import InfoToolTip from '@components/generic/InfoToolTip'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { newSearchSlice } from '@redux/reducers/newSearch'

type Props = {
  deployFilters?: boolean
  segmentBase?: string
  setHasFourStar?: (val: boolean) => void
  hasFourStar?: boolean
}

function FourStarRating({
  segmentBase = '',
  deployFilters = true,
  setHasFourStar,
  hasFourStar,
}: Props) {
  const dispatch = useThunkDispatch()
  const { filters } = useAppSelector((state) => state.newSearch)

  const ratinginfo =
    'Filter for only highly-rated buildings. Based on reviews from past and current renters!'

  return (
    <div>
      <div className="mt-4 rounded-lg bg-white p-4">
        <div className="flex items-center justify-between space-x-2">
          <div className="flex items-center space-x-2">
            <div className="text-sm font-semibold text-dark-900">4-Star Rating +</div>
            <InfoToolTip
              tip={ratinginfo}
              id="rating-info"
              direction={isMobile ? 'bottom' : 'right'}
              mobileResponsive="sm:!ml-4 sm:!w-96 !ml-12"
            />
          </div>
          <Switch
            onColor="#050035"
            offColor="#C1C7D0"
            className="origin-center scale-[0.8]"
            checkedIcon={false}
            checkedHandleIcon={<FaCheck color="#050035" size={14} className="mx-auto my-1.5" />}
            uncheckedIcon={false}
            onChange={async (v) => {
              segmentBase === 'home'
                ? segment?.home_listings_filter_tenant_ratings(
                    filters.hasBuildingMinFourRating ? false : true
                  )
                : segment?.listings_filter_tenant_ratings(
                    filters.hasBuildingMinFourRating ? false : true
                  )
              if (deployFilters) {
                dispatch(
                  newSearchSlice.actions.setFilter({
                    hasBuildingMinFourRating: filters.hasBuildingMinFourRating ? null : true,
                  })
                )
              } else {
                setHasFourStar(v)
              }
            }}
            checked={!deployFilters ? hasFourStar : filters.hasBuildingMinFourRating ?? false}
          />
        </div>
      </div>
    </div>
  )
}

export default FourStarRating
