import { isMobile } from 'react-device-detect'
import { FaCheck } from 'react-icons/fa'
import Switch from 'react-switch'
import { segment } from 'src/lib/Segments'

import InfoToolTip from '@components/generic/InfoToolTip'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { newSearchSlice } from '@redux/reducers/newSearch'

type props = {
  customCss?: string
  deployFilters?: boolean
  segmentBase?: string
  setGoodCause?: (val: boolean) => void
  goodCause?: boolean
}

function GoodCause({
  customCss = '',
  segmentBase = 'onboarding',
  deployFilters = true,
  setGoodCause,
  goodCause,
}: props) {
  const dispatch = useThunkDispatch()
  const { filters } = useAppSelector((state) => state.newSearch)

  const goodinfo =
    'Living in a "Good Cause" building means you are entitled to a lease renewal and capped rent increases (if you follow all the terms of your lease).'

  return (
    <div className={`mt-4 rounded-lg bg-white p-4 ${customCss}`}>
      <div className="flex items-center justify-between space-x-2">
        <div className="flex items-center space-x-2">
          <div className="text-sm font-semibold text-dark-900">Good Cause Eviction</div>
          <InfoToolTip
            tip={goodinfo}
            id="good-info"
            direction={isMobile ? 'bottom' : 'right'}
            mobileResponsive="sm:!ml-4 sm:!w-96 !ml-4"
          />
        </div>
        <Switch
          onColor="#050035"
          offColor="#C1C7D0"
          className="origin-center scale-[0.8]"
          checkedIcon={false}
          checkedHandleIcon={<FaCheck color="#050035" size={14} className="mx-auto my-1.5" />}
          uncheckedIcon={false}
          onChange={async (v) => {
            segmentBase === 'home'
              ? segment?.home_listings_filter_goodcause(
                  filters.hasBuildingGoodCauseEviction ? false : true
                )
              : segment?.['listings_' + segmentBase + '_goodcause']?.(
                  filters.hasBuildingGoodCauseEviction ? false : true
                )
            if (deployFilters) {
              dispatch(
                newSearchSlice.actions.setFilter({
                  hasBuildingGoodCauseEviction: filters.hasBuildingGoodCauseEviction ? null : true,
                })
              )
            } else {
              setGoodCause(v)
            }
          }}
          checked={!deployFilters ? goodCause : filters.hasBuildingGoodCauseEviction ?? false}
        />
      </div>
    </div>
  )
}

export default GoodCause
