import { isMobile } from 'react-device-detect'
import { FaCheck } from 'react-icons/fa'
import Switch from 'react-switch'
import { segment } from 'src/lib/Segments'

import InfoToolTip from '@components/generic/InfoToolTip'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { newSearchSlice } from '@redux/reducers/newSearch'

type props = {
  customCss?: string
  deployFilters?: boolean
  segmentBase?: string
  setRentStabilized?: (val: boolean) => void
  rentStabilized?: boolean
}

function RentStabilized({
  customCss = '',
  segmentBase = '',
  deployFilters = true,
  setRentStabilized,
  rentStabilized,
}: props) {
  const dispatch = useThunkDispatch()
  const { filters } = useAppSelector((state) => state.newSearch)

  const rentinfo =
    'There are a million stabilized apartments in NYC. Having one means you are entitled to a renewal and limited rent increases.'

  return (
    <div className={`rounded-lg bg-white p-4 ${customCss}`}>
      <div className="flex items-center justify-between space-x-2">
        <div className="leading-1 inline items-center text-sm font-semibold text-dark-900">
          <span className="mr-2 inline align-middle">Buildings with Rent-Stabilized Units</span>
          <div className="inline-block align-middle">
            <InfoToolTip
              tip={rentinfo}
              id="rent-info"
              direction={isMobile ? 'bottom' : 'right'}
              mobileResponsive="sm:!ml-4 sm:!w-96 !ml-16"
            />
          </div>
        </div>
        <Switch
          onColor="#050035"
          offColor="#C1C7D0"
          className="origin-center scale-[0.8]"
          checkedIcon={false}
          checkedHandleIcon={<FaCheck color="#050035" size={14} className="mx-auto my-1.5" />}
          uncheckedIcon={false}
          onChange={async (v) => {
            segmentBase === 'home'
              ? segment?.home_listings_filter_rent_stabilized(
                  filters.isBuildingRentStabilized ? false : true
                )
              : segment?.listings_filter_rent_stabilized(
                  filters.isBuildingRentStabilized ? false : true
                )
            if (deployFilters) {
              dispatch(
                newSearchSlice.actions.setFilter({
                  isBuildingRentStabilized: filters.isBuildingRentStabilized ? null : true,
                })
              )
            } else {
              setRentStabilized(v)
            }
          }}
          checked={!deployFilters ? rentStabilized : filters.isBuildingRentStabilized ?? false}
        />
      </div>
    </div>
  )
}

export default RentStabilized
